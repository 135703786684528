<template>
  <div>

    <div id="map">

      <v-overlay v-model="dialog" persistent>
        <template>
          <v-card class="card-table mx-16 my-5 transparent">
            <v-simple-table class="data-table" light dense>
              <template v-slot:default>
                <thead>
                  <tr height="80" style="background: #073662 !important; color: white">
                    <td> Parameter</td>
                    <template v-for="item in stationDetails">
                      <td :key="item.STATION_ID_A" class="text-center text-uppercase">{{ item.STATION_ID_A }} , {{
                        item.LOCATION }} <br> {{new Date(item.DATETIME_A).toLocaleDateString("en-GB") + ", " + new
                        Date(item.DATETIME_A).toLocaleTimeString()
                        }} </td>
                    </template>

                  </tr>
                </thead>
                <tbody>
                  <tr height="50">
                    <td> pH </td>
                    <template v-for="item in stationDetails">
                      <td :key="item.STATION_ID_A" class="text-center">
                        <v-chip v-if='item.pH != null' :color="'class-' + item.PARAM_PH_CLASS"> {{ item.pH }} </v-chip>
                      </td>
                    </template>

                  </tr>
                  <tr height="50" style="background: #EEECEC !important">
                    <td> Dissolved Oxygen (Concentration) (mg/L)</td>
                    <template v-for="item in stationDetails">
                      <td :key="item.STATION_ID_A" class="text-center">
                        <v-chip v-if='item.DO_CON != null' :color="'class-' + item.PARAM_DO_SAT_CLASS"> {{ item.DO_CON
                          }} </v-chip>
                      </td>
                    </template>

                  </tr>

                  <tr height="50" >
                    <td> Total Suspended Solid (mg/L) </td>
                    <template v-for="item in stationDetails">
                      <td :key="item.STATION_ID_A" class="text-center">
                        <v-chip v-if='item.TSS != null' :color="'class-' + item.PARAM_TSS_CLASS"> {{ item.TSS
                          }} </v-chip>
                      </td>
                    </template>

                  </tr>

                  <tr height="50" style="background: #EEECEC !important">
                    <td> Turbidity (NTU)</td>
                    <template v-for="item in stationDetails">
                      <td :key="item.STATION_ID_A" class="text-center">{{ item.TURBIDITY }} </td>
                    </template>

                  </tr>

                  <tr height="50" >
                    <td> Ammoniacal Nitrogen (mg/L)  </td>
                    <template v-for="item in stationDetails">
                      <td :key="item.STATION_ID_A" class="text-center">
                        <v-chip v-if='item.NH3N != null' :color="'class-' + item.PARAM_NH3N_CLASS"> {{ item.NH3N
                          }} </v-chip>
                      </td>
                    </template>

                  </tr>

                  <tr height="50" style="background: #EEECEC !important">
                    <td> Conductivity (µS/cm) </td>
                    <template v-for="item in stationDetails">
                      <td :key="item.STATION_ID_A" class="text-center">{{ item.CONDUCTIVITY }} </td>
                    </template>

                  </tr>

                  <tr height="50">
                    <td> Water Level (m)</td>
                    <template v-for="item in stationDetails">
                      <td :key="item.STATION_ID_A" class="text-center">{{ item.WATER_LEVEL }} </td>
                    </template>

                  </tr>

                  <tr height="50" style="background: #EEECEC !important">
                    <td> Flowrate</td>
                    <template v-for="item in stationDetails">
                      <td :key="item.STATION_ID_A" class="text-center">{{ item.FLOWRATE }} </td>
                    </template>

                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card>

          <v-card class="card-info black--text color transparent align-center justify-center text-center">
            <v-card-title
              class="align-center justify-center text-center card-header white--text pa-5">Information</v-card-title>
            <v-card-subtitle class="align-center justify-center white white--text pa-5">
              <v-row class="align-center justify-center text-center">
                <v-col cols="2.5">
                  <span class="align-center justify-center class_I "></span><br>
                  <span class="align-center justify-center black--text "> CLASS I </span>
                </v-col>
                <v-col cols="2.5">
                  <span class="align-center justify-center class_II"></span><br>
                  <span class="align-center justify-center black--text"> CLASS II </span>
                </v-col>
                <v-col cols="2.5">
                  <span class="align-center justify-center class_III "></span><br>
                  <span class="align-center justify-center black--text"> CLASS III </span>
                </v-col>
                <v-col cols="2.5">
                  <span class="align-center justify-center class_IV "></span><br>
                  <span class="align-center justify-center black--text"> CLASS IV </span>
                </v-col>
                <v-col cols="2.5">
                  <span class="align-center justify-center class_V "></span><br>
                  <span class="align-center justify-center black--text"> CLASS V </span>
                </v-col>
              </v-row>


            </v-card-subtitle>

          </v-card>
        </template>



      </v-overlay>



    </div>


  </div>
</template>

<script>
  // -- import something here --

  import "ol/ol.css";

  import Map from "ol/Map";
  import View from "ol/View";
  import { defaults as defaultControls, ScaleLine } from "ol/control";
  import {
    Tile as TileLayer,
    Vector as VectorLayer,
    Group as GroupLayer,
    Image as ImageLayer
  } from "ol/layer";
  import { OSM, Vector as VectorSource, ImageArcGISRest } from "ol/source";
  import { Fill, Stroke, Circle, Style, Icon } from "ol/style";
  import Feature from "ol/Feature";
  import { fromLonLat } from "ol/proj";
  import { Point } from "ol/geom";
  import Overlay from "ol/Overlay";

  // Axios for fetching data
  import axios from "axios";

  export default {

    data: () => ({

      parametersToDisplay: ['PH', 'DO_SAT', 'TSS', 'WATER_LEVEL', 'FLOWRATE'],

      dialog: true,

      stationDetails: [],

      mapIcon: {
        green: new Style({
          image: new Circle({
            fill: new Fill({
              color: "#00ff08",
            }),
            stroke: new Stroke({
              color: "#ffffff ",
              width: 3,
            }),
            radius: 10,
          }),
        }),
        red: new Style({
          image: new Circle({
            fill: new Fill({
              color: "#fb4343",
            }),
            stroke: new Stroke({
              color: "#fb4343",
              width: 1.25,
            }),
            radius: 8,
          }),
        }),
        orange: new Style({
          image: new Circle({
            fill: new Fill({
              color: "#fc8414",
            }),
            stroke: new Stroke({
              color: "#fc8414",
              width: 1.25,
            }),
            radius: 8,
          }),
        }),
        grey: new Style({
          image: new Circle({
            fill: new Fill({
              color: "#7c8a94",
            }),
            stroke: new Stroke({
              color: "#7c8a94",
              width: 1.25,
            }),
            radius: 8,
          })
        }),
        cyan: new Style({
          image: new Circle({
            fill: new Fill({
              color: "#078a88",
            }),
            stroke: new Stroke({
              color: "#078a88",
              width: 1.25,
            }),
            radius: 8,
          })
        })
      },


      // Map Declaration

      map: null,
      view: null,
      extent: [102.50629968364994, 1.4659878852413384, 104.12834976301205, 2.4383877088896453],
      baseMapLayer: {
        topographicMap: new TileLayer({
          title: "Topographic Map",
          source: new OSM({
            url:
              "https://server.arcgisonline.com/ArcGIS/rest/services/World_Topo_Map/MapServer/tile/{z}/{y}/{x}",
            crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
        }),
        boundaryLayer: new ImageLayer({
          title: "Topographic Map",
          source: new ImageArcGISRest({
            url:
              "https://eqmp.scienotech.com.my/arcgis/rest/services/DEMARCATION/STATE/MapServer",
          }),
          visible: false,
          type: "layer",
        }),
        imageryMap: new TileLayer({
          title: "Imagery Map",
          source: new OSM({
            // "url" : 'http://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}',
            url:
              "https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}",
            // "url": 'https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}',
            crossOrigin: "anonymous",
          }),
          visible: true,
          type: "base",
        }),
        streetMap: new TileLayer({
          title: "OSM",
          source: new OSM({
            url:
              "https://server.arcgisonline.com/ArcGIS/rest/services/World_Street_Map/MapServer/tile/{z}/{y}/{x}",
            crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
        }),
        googleStreetMap: new TileLayer({
          title: "Google Street",
          source: new OSM({
            url: "https://mt1.google.com/vt/lyrs=r&x={x}&y={y}&z={z}",
            // "url": 'http://mt1.googleapis.com/vt?x={x}&y={y}&z={z}',
            crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
        }),
        googleSateliteMap: new TileLayer({
          title: "OSM",
          source: new OSM({
            url: "https://mt1.google.com/vt/lyrs=s&x={x}&y={y}&z={z}",
            crossOrigin: "anonymous",
          }),
          visible: false,
          type: "base",
        }),
      },
      pointStyle: {
        default: new Style({
          image: new Circle({
            fill: new Fill({
              color: "#3381ff",
            }),
            stroke: new Stroke({
              color: "#0242ab",
              width: 1.25,
            }),
            radius: 8,
          }),
        }),
        selected: new Style({
          image: new Circle({
            fill: new Fill({
              color: "#00ffff",
            }),
            stroke: new Stroke({
              color: "#003300",
              width: 1.25,
            }),
            radius: 8,
          }),
        }),

      }
    }),

    mounted() {
      this.initiateMap();
      this.getParamInfo();
      this.transition();
    },

    methods: {

      transition() {
        setTimeout(() => {
          this.$router.push({ path: '/PublicDisplay/WQMS01' }).catch(() => { });
        }
          , 5000);
      },

      initiateMap() {
        var source = new VectorSource();
        var vector = new VectorLayer({
          source: source,
        });

        var baseMap = new GroupLayer({
          layers: [
            this.baseMapLayer.topographicMap,
            this.baseMapLayer.imageryMap,
            this.baseMapLayer.streetMap,
            this.baseMapLayer.googleStreetMap,
            this.baseMapLayer.googleSateliteMap,
          ],
        });

        this.map = new Map({
          controls: defaultControls({
            zoom: false,
          }),
          target: "map",
          layers: [baseMap],
          view: new View({
            projection: "EPSG:4326",
            zoom: 1,
          }),
        });

        this.view = this.map.getView();
        this.view.fit(this.extent);

        this.map.on('moveend', this.onMoveEnd)

        this.onClickMap();

      },

      onClickMap() {
        let that = this;

        this.map.on("click", function (evt) {
          console.log("hehe");
        });
      },

      onMoveEnd(evt) {

        console.log(evt.pixel);
        // console.log(this.view);

        const map = evt.map;
        console.log("extent2", map.getView().calculateExtent())
        // console.log(fromLonLat(evt.coordinate, 'EPSG:3857', 'EPSG:4326'));
      },

      getParamInfo() {
        axios
          .get(this.globalUrl + "/bakaj/paramspublic", {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          })
          .then((response) => {
            this.paramDetails = response.data;

            this.getStationInfo();

          })
          .catch((error) => {
            console.log(error);
          });
      },

      getStationInfo() {
        axios
          .get(this.globalUrl + "/bakaj/readingpublic", {
            // .get("https://run.mocky.io/v3/98873f4d-ebf7-44ff-9565-4fa2674af35c", {
            headers: {
              Authorization: "Bearer " + this.$store.getters.user.token,
            },
          })
          .then((response) => {
            this.stationDetails = response.data;

            this.addMapMarker();

            //   this.getStationInfoList();

          })
          .catch((error) => {
            console.log(error);
          });
      },

      addMapMarker() {
        //   this.addborderLayer();

        for (let i = 0; i < this.stationDetails.length; i++) {
          var marker = new Feature({
            geometry: new Point([
              this.stationDetails[i].LONGITUDE,
              this.stationDetails[i].LATITUDE,
            ]),
            description: this.stationDetails[i],
          });
          var vectorSource = new VectorSource({
            features: [marker],
          });



          var markerVectorLayer = new VectorLayer({
            name: "Automated",
            source: vectorSource,
            style: this.stationDetails[i].GREEN == "TRUE"
              ? this.mapIcon.green
              : this.stationDetails[i].GREY == "TRUE"
                ? this.mapIcon.grey
                : this.stationDetails[i].YELLOW == "TRUE"
                  ? this.mapIcon.green
                  : this.mapIcon.green,
          });

          this.map.addLayer(markerVectorLayer);
        }
        // this.addMapOverlayReading();

        this.addMapOverlay();
      },

      addMapOverlay() {
        for (let i = 0; i < this.stationDetails.length; i++) {

          let labelStationID = "label_" + this.stationDetails[i].STATION_ID_A;

          let divLabel = document.createElement("DIV");

          divLabel.setAttribute("id", labelStationID);
          divLabel.setAttribute("ref", labelStationID);

          divLabel.setAttribute("style", "background:#5A5A5A ;color: white !important;z-index: 100; letter-spacing: 1px; padding: 4px");

          let positioning

          if (this.stationDetails[i].STATION_ID_A == 'BKJ006' || this.stationDetails[i].STATION_ID_A == 'BKJ005' || this.stationDetails[i].STATION_ID_A == 'BKJ001')
            positioning = [-100, -15]
          else if (this.stationDetails[i].STATION_ID_A == 'BKJ003' || this.stationDetails[i].STATION_ID_A == 'BKJ004')
            positioning = [100, -13]
          else if (this.stationDetails[i].STATION_ID_A == 'BKJ002')
            positioning = [123, -13]
          else
            positioning = [0, -55]



          divLabel.innerHTML = this.stationDetails[i].STATION_ID_A + ", " + this.stationDetails[i].LOCATION;

          document.getElementById("map").appendChild(divLabel);


          let overlayLabel = new Overlay({
            element: document.getElementById(labelStationID),
            id: "label__" + this.stationDetails[i].STATION_ID_A,
            positioning: "top-center",
            offset: positioning
          });

          overlayLabel.setPosition([
            this.stationDetails[i].LONGITUDE,
            this.stationDetails[i].LATITUDE,
          ]);

          this.map.addOverlay(overlayLabel);
        }
      },

    },

  };
</script>

<style lang="scss" scoped>
  @import '~scss/main';


  #map {
    position: fixed;
    margin: 0;
    padding: 0;
    height: 100%;
    width: 100%;
    //   background: black;
  }


  .card-table {
    top: -10px;
    width: calc(100vw - 200px) !important;

  }

  .data-table {
    width: 100% !important;
  }

  .card-info {
    margin-left: 200px;
    margin-right: 200px;
    ;
  }


  #map::v-deep .class-I {
    background-color: #051DF6 !important;
    color: white;
  }

  #map::v-deep .class-II {
    background-color: #05F61D !important;
    color: black;
  }

  #map::v-deep .class-III {
    background-color: #F5E700 !important;
    color: black;
  }

  #map::v-deep .class-IV {
    background-color: #F6C105 !important;
    color: black;
  }

  #map::v-deep .class-V {
    background-color: #F60505 !important;
    color: white;
  }

  #map::v-deep .class-text-NA {
    background-color: #cccccc !important;
  }


  #map::v-deep .class-text-I {
    color: #051DF6 !important;
  }

  #map::v-deep .class-text-II {
    color: #05F61D !important;
  }

  #map::v-deep .class-text-III {
    color: #F5E700 !important;
  }

  #map::v-deep .class-text-IV {
    color: #F6C105 !important;
  }

  #map::v-deep .class-text-V {
    color: #F60505 !important;
  }

  #map::v-deep .class-text-NA {
    color: #cccccc !important;
  }


  #map::v-deep .class_I {
    height: 25px;
    width: 25px;
    background-color: #2d7ef8;
    border-radius: 40%;
    display: inline-block;
  }

  #map::v-deep .class_II {
    height: 25px;
    width: 25px;
    background-color: #05F61D;
    border-radius: 40%;
    display: inline-block;
  }

  #map::v-deep .class_III {
    height: 25px;
    width: 25px;
    background-color: #F5E700;
    border-radius: 40%;
    display: inline-block;
  }

  #map::v-deep .class_IV {
    height: 25px;
    width: 25px;
    background-color: #F6C105;
    border-radius: 40%;
    display: inline-block;
  }

  #map::v-deep .class_V {
    height: 25px;
    width: 25px;
    background-color: #F60505;
    border-radius: 40%;
    display: inline-block;
  }
</style>